<template>
  <div class="container">
    <div class="main">
      <div class="title">登录</div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="account">
          <el-input v-model="form.account" placeholder="请输入账号" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" show-password placeholder="请输入密码" prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login" style="width: 100%">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { encode, decode } from 'js-base64';
export default {
  name:"logins",
  data(){
    return {
       form:{
         account:'',
         password:''
       },
       rules:{
          account: [
            { required: true, message: '请输入账号', trigger: 'change' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'change' }
          ],
       }
    }
  },
  methods:{
    login(){
      this.$refs['form'].validate((valid) => {
        if(valid){
          let code = `Basic ${encode(`${this.form.account}:${this.form.password}`)}`
          this.$store.commit('USERNAME',this.form.account)
          this.$store.commit('TOKEN',code)
          this.$router.replace('/index')
        }
      })
    }
  }
}
</script>
<style scoped lang='less'>
   
</style>
<style lang="less">
.container{
  background: rgb(240, 244, 248);
  width: 100%;
  min-height: 100%;
  background-image: url('../assets/background.svg');
  background-size: cover;
  background-position: 50%;
  position: relative;
  .main{
    width: 400px;
    padding: 24px;
    height: 240px;
    background: #FFFFFF;
    border-radius: 6px;
    position: absolute;
    right: calc(50% - (400px / 2));
    top: calc(50% - (400px / 2));
    .title{
      width: 100%;
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 30px;
      text-align: center;
    }
  }
}
</style>