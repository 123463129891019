import {get, post, php_post} from './axios.js';
 
let bsae_api = 'https://iocr.huoxingqianli.com'

 
export default {
  bsae_api: bsae_api,
  getOrderList : params => { return post(`${bsae_api}/member/memberOrder`,params) },
  getTempList : params => { return post(`${bsae_api}/templat/templatList`,params) },
  setTempAdd : params => { return post(`${bsae_api}/templat/addTemplat`,params) },
  setTempEdit : params => { return post(`${bsae_api}/templat/editTemplat`,params) },
  setTempDel : params => { return post(`${bsae_api}/templat/delete`,params) },
  getBlockList : params => { return post(`${bsae_api}/templat/blockList`,params) },
  setBlockAdd : params => { return post(`${bsae_api}/templat/addBlock`,params) }
}