import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    username: null 
  },
  mutations: {
    TOKEN(state, token){
      if(token){
        localStorage.setItem('orc_token', token)
      }else{
        localStorage.removeItem('orc_token')
      }
      state.token = token
    },
    USERNAME(state, username){
      if(username){
        localStorage.setItem('orc_username', username)
      }else{
        localStorage.removeItem('orc_username')
      }
      state.username = username
    }
  },
  getters:{
    token(state) {
			return state.token || localStorage.getItem('orc_token')
		},
    username(state) {
      return state.username || localStorage.getItem('orc_username')
    }
  },
  actions: {
  },
  modules: {
  }
})
