<template>
  <layout></layout>
</template>
<script>
import layout from '../components/layouts.vue'
export default {
  name:"",
  components: { layout },
  data(){
    return {
       
    }
  },
}
</script>
<style scoped lang='less'>
   
</style>