<template>
  <div class="headers">
    <div class="left">
      <div class="logo">货行千里-OCR</div>
      <el-menu
        :default-active="defaultActive"
        router
        mode="horizontal"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#fff">
        <el-menu-item index="/index/template">模板管理</el-menu-item>
        <el-menu-item index="/index/order">订单管理</el-menu-item>
      </el-menu>
    </div>
    <div class="right">
      <el-avatar icon="el-icon-user-solid"></el-avatar>
      <el-dropdown @command="handleCommand" style="margin-left: 20px">
        <span class="el-dropdown-link">
          {{ username ? username : '请登录' }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name:"headers",
  computed: {
    ...mapGetters(['username'])
  },
  data(){
    return {
       defaultActive:''
    }
  },
  watch:{
    $route(val){
      this.defaultActive = val.matched[1].path || ''
    }
  },
  created(){
    this.defaultActive = this.$route.matched[1].path || ''
  },
  methods: {
    handleCommand(command) {
      if(command == 'logout'){
        this.$store.commit('TOKEN',null)
        this.$store.commit('USERNAME',null)
        this.$router.replace('/login') 
      }
    }
  }
}
</script>
<style scoped lang='less'>
.headers{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left{
    height: 100%;
    display: flex;
    .logo{
      color: #fff;
      font-size: 30px;
      line-height: 60px;
      margin-right: 40px;
    }
  }
  .right{
    display: flex;
    align-items: center;
    .el-dropdown-link{
      color: #fff;
      cursor: pointer;
    }
  }
}
.el-menu-item.is-active{
  background: rgb(67, 74, 80) !important;
}
</style>