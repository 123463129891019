<template>
  <el-container style="height: 100%">
    <el-header style="background: #545c64">
      <headers></headers>
    </el-header>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</template>
<script>
import headers from './headers.vue'
export default {
  name:"layouts",
  components: { headers },
  data(){
    return {
       
    }
  },
}
</script>
<style scoped lang='less'>
   
</style>