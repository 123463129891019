import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import router from '../../router'

const serve = axios.create({
  timeout: 60000,
  withCredentials: false,
  headers: { 'Content-Type': 'application/json' },
})
// 拦截器 -- 请求前
serve.interceptors.request.use((config)=>{

  return config;
},error=>{
  return Promise.reject(error);
})
// 连接器 -- 响应
serve.interceptors.response.use((response) => {
  if(response.data.status == 900){
    localStorage.removeItem('token');
    router.replace('/login')
  }
  return response;
}, error => {
  console.log(error,Vue.prototype)
  Vue.prototype.$notify.error({
    title: '网络错误',
    message: error.message
  })
  console.log(Vue)
  return Promise.reject(error);
})
 
export function get(url, params) {
  return new Promise((resolve, reject) => {
      serve.get(url, {
          params: params,
          headers:{
            Authorization:localStorage.getItem('orc_token')
          }
      }).then(res => {
        console.log(res.data)
        // if(res.data)
        resolve(res.data);
      }).catch(err => {
          reject(err.data)
      })
  });
}
 
export function post(url, params) {
  return new Promise((resolve, reject) => {
      serve.post(url, params,{
        headers:{
          Authorization:localStorage.getItem('orc_token')
        }
      })
          .then(res => {
            if(res.data.status != 200){
              Vue.prototype.$notify.error({
                title: res.data.msg
              })
            }
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          })
  });
}

const php_serve = axios.create({
  timeout: 10000,
  headers: {'Content-Type': 'application/x-www-form-urlencoded'},
})

// 连接器 -- 响应
php_serve.interceptors.response.use((response) => {
  if(response.data.code == 900){
    window.location.href = "http://wall-data.huoxingqianli.com/login.html";
    localStorage.removeItem('token');
  }
  return response;
}, error => {
  return Promise.reject(error);
})

export function php_post(url, params) {
  return new Promise((resolve, reject) => {
    php_serve.post(url, qs.stringify(params),{
      headers:{
        token:localStorage.getItem('token')
      }
    })
          .then(res => {
              resolve(res.data);
          })
          .catch(err => {
              reject(err.data);
          })
  });
}