import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: "/login"
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
    redirect:'/index/template',
    children: [
      {
        path: "template",
        name: "template",
        component: () => import("../views/Temp.vue"),
        redirect:'/index/template/list',
        children: [
          {
            path: "list",
            name: "templateList",
            component: () => import("../views/Temp/list.vue"),
          },
          {
            path: "block",
            name: "templateBlock",
            component: () => import("../views/Temp/block.vue"),
          }
        ]
      },
      {
        path: "order",
        name: "order",
        component: () => import("../views/Order.vue"),
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
